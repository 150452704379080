<template>
    <div style="width: 100%; height: 100%;">
        <background />
        
        <div v-show="!loading">
            <v-card flat class="shadow rounded-xl" style="width:100%;">
                <v-img src="@/assets/Location-05.svg" width="150" style="margin:0 auto;" v-if="isError"></v-img>
                <div class="text-center" v-else>
                    <v-icon size="150" color="accent">mdi-map-marker-check-outline</v-icon>
                </div>

                <div class="headline ma-3 text-center" :style="`color:${color}`">{{ message }}</div>

                <v-card-text v-if="showDetails">
                    <v-list>
                        <v-subheader>Detalles de la sesión</v-subheader>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ sessionName }}</v-list-item-title>
                                <v-list-item-subtitle>Nombre de la sesión</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ sessionType }}</v-list-item-title>
                                <v-list-item-subtitle>Tipo de sesión</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <!-- remaining sessions -->
                    <v-card color="secondary" flat class="rounded-xl white--text" v-if="!isError">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <v-avatar class="mr-3 text-h2" size="125" tile>
                                {{ remainingSessions }}
                            </v-avatar>

                            <div class="d-flex align-center">
                                <div>
                                    <span class="text-h6">Sesiones disponible</span>
                                    <!-- <v-btn depressed small color="accent" v-if="showRenew" @click="renew">Renovar</v-btn> -->
                                </div>
                            </div>
                        </div>
                    </v-card>
                    
                    <v-btn block depressed color="primary" class="mt-5" v-if="!isError && showRenew" @click="renew">Renovar</v-btn>
                </v-card-text>
    
                <!-- <v-card-actions v-if="!isError && showRenew" class="pb-5">
                    <v-btn block depressed color="primary" @click="renew">Renovar</v-btn>
                </v-card-actions> -->

                <v-card-text class="pt-0" v-if="isError && errorCode == 'nosubscription'">
                    <v-card-title class="headline">Suscríbete</v-card-title>

                    <v-card class="pa-0 my-2 shadow2 white--text" color="primary" to="/productos/presencial">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <v-avatar class="mr-3" size="125" tile >
                                <v-img src="@/assets/3785069.png"></v-img>
                            </v-avatar>

                            <div>
                                <v-card-title class="text-h5 pt-2">
                                    Presencial
                                </v-card-title>

                                <v-card-subtitle class="pb-2 white--text">
                                    Únete a nuestra comunidad y entrena junto a las Petu Girls
                                </v-card-subtitle>
                            </div>
                        </div>
                    </v-card>

                    <v-card class="pa-0 my-2 shadow2 white--text" color="secondary" @click="oneOnOneDialog = true">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <v-avatar class="mr-3" size="125" tile >
                                <v-img src="@/assets/3785069-3.png"></v-img>
                            </v-avatar>

                            <div>
                                <v-card-title class="text-h5 pt-2">
                                    One on one
                                </v-card-title>

                                <v-card-subtitle class="pb-2 white--text">
                                    Clases individuales de la mano de nuestras Petu Trainers
                                </v-card-subtitle>
                            </div>
                        </div>
                    </v-card>

                    <!-- <v-row>
                        <v-col cols="6">
                            <v-card class="pa-0">
                                <v-img src="@/assets/3785069.png"></v-img>
                                <v-card-title>
                                    Presencial
                                </v-card-title>

                                <v-card-subtitle>
                                    Únete a nuestra comunidad y toma el Petu Entrenamiento junto con las Petu Girls
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card class="pa-0">
                                <v-img src="@/assets/3785069-3.png"></v-img>
                                <v-card-title>
                                    One on one
                                </v-card-title>

                                <v-card-subtitle>
                                    Toma clases individualizadas con una de nuestras Petu Trainers
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row> -->
                </v-card-text>
            </v-card>
        </div>

        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card class="petu-green-bg pa-4" dark>
                <span>Haciendo check-in</span>
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card>
        </v-dialog>
        <v-dialog v-model="oneOnOneDialog">
			<v-card>
				<v-card-title>Entrenamiento One on One</v-card-title>
				<v-card-text>
					Para este tipo de entrenamiento debes comunicarte directamente al petu phone, <a href="tel:+19394896563">(939) 489-6563</a> para coordinar agenda con la petu trainer.
				</v-card-text>
				<v-card-actions>
					<v-btn depressed block color="primary" @click="oneOnOneDialog = false">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PetuCustomer } from '@/classes/customer';
import Background from '@/components/Background';
import { _st } from '@/softech';
export default {
    props: {
        sessionId: { type: String, default: '' }
    },
    data() {
        return {
            loading             : true,

            isError             : false,
            errorCode           : '',

            message             : '',
            color               : '#000',
            sessionName         : '',
            sessionType         : '',

            showRenew           : false,
            oneOnOneDialog      : false,
            product             : null,
            remainingSessions   : 0,
        }
    },
    computed: {
        ...mapGetters({
            user        : 'auth/user',
            customer    : 'auth/customer'
        }),
        showDetails() {
            return !(_st.isEmpty( this.sessionName ) || _st.isEmpty( this.sessionType ) );
        }
    },
    async mounted() {
        if( _st.isNU( this.user ) || _st.isNU( this.customer ) ) {
            this.$router.push('/');
            return;
        }

        try {
            // check if customer exist with the same email address
            let customer = new PetuCustomer( this.customer );
            let checkin = await customer.checkIn( this.sessionId );
            // console.log(checkin);

            this.message = '¡Gracias por hacer check-in!';
            this.color = '#639d43';
            this.sessionName = checkin.sessionName;
            this.sessionType = checkin.sessionType;
            this.remainingSessions = checkin.remainingSessions;

            if( this.remainingSessions <= 3 ) {
                this.product = checkin.product;
                this.showRenew = true;
            }
        }
        catch(error) {
            if( !_st.isNU( error.data.code ) && error.error !== true ) {
                this.isError = true;
                this.errorCode = error.data.code;
                this.message = error.data.message;
                this.color = '#f68926';
                this.sessionName = _st.isNUE( error.data.sessionName ) ? '' : error.data.sessionName;
                this.sessionType = _st.isNUE( error.data.sessionType ) ? '' : error.data.sessionType;

                if( !_st.isNUE( error.data.goto ) )
                    this.$router.push( error.data.goto );
            }
        }
        finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({ 
            updateCustomer  : 'auth/updateCustomer', 
            addToCart       : 'cartInfo/addToCart'
        }),
        async renew() {
            try {
                await this.addToCart({
                    productId: this.product.id,
                    qty: 1
                });

                this.$router.push('/checkout');
            }
            catch(error) {
                console.log(error);
            }
        }
    },
    components: {
        Background
    }
}
</script>

<style lang="scss" scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
.shadow2 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>